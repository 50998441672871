<template>
    <div class="container card bs-5 mb-5">
        <div class="fl-te-c pb-3">
            <h4 v-html="'CHANGE PASSWORD'"></h4>
        </div>
        <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
        :save-params="{training: $route.params.id}">

        <div class="row mr-0 col-12">
            <validated-input class="col-4" label="Old Password" v-model="model.old_password"
                         :rules="rules.old_password" type="password" :disabled="loading"/>

            <validated-input class="col-4" label="New Password" v-model="model.new_password"
                         :rules="rules.new_password" type="password" :disabled="loading"/>

            <validated-input class="col-4" label="New Password Retype" v-model="model.new_password_retype"
                         :rules="rules.new_password_retype" type="password" :disabled="loading"/>
        </div>

        <div class="row col-12 mr-1 fl-eqh-r">
            <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                                 loading-text="Saving..." :disabled="loading"/>
        </div>
    </b-form>
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name : 'ChangePassword',
    data () {
        return {
            addUrl : urls.auth.changePassword,
            rules  : {
                old_password : {
                    required : true
                },
                new_password : {
                    required : true
                },
                new_password_retype : {
                    required : true
                }
            }
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Changed the Password..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$router.push({ path : '/' }).then(() => {
                setTimeout(() => {
                    window.location.reload(true);
                }, 2000);
            }); ;
        }
    }
};
</script>

<style scoped>

</style>
